import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'sign-in',
    redirect: '/sign-in',
  },
  {
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Signin/Sign-In.vue'),
	},
  {
		path: '/user',
		name: 'user',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/user.vue'),

   
	},
	{
		path: '/user/create',
		name: 'usercreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/User/usercreate.vue'),
	},
	{
        name: 'Useredit',
        path: '/user/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/useredit.vue'),

	},
	{
		path: '/role',
		name: 'role',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/role.vue'),
	},
	{
		path: '/role/create',
		name: 'rolecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/create.vue'),
	},
	{
		path: '/permission',
		name: 'permission',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Permission/permission.vue'),
	},
	{
		path: '/profile_list',
		name: 'profile_list',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/list.vue'),
	},

	{
        name: 'edit-profile',
        path: '/editprofile',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/edit.vue'),

	},
	{
        name: 'change_password',
        path: '/change_password',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/changepassword.vue'),

	},
	{
		path: '/product_list',
		name: 'product_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Product/list.vue'),
	},
	{
		path: '/product/create',
		name: 'productcreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Product/prod_create.vue'),
	},
	{
        name: 'view_product',
        path: '/view_product/:id',
		meta:{requiresAuth:true},

		component: () => import('../views/Product/view.vue'),

	},
	{
        name: 'prod_edit',
        path: '/productedit/:id',
		
		meta:{requiresAuth:true},

		component: () => import('../views/Product/prod_edit.vue'),

	},
	{
		path: '/category_list',
		name: 'category_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Category/list.vue'),
	},
	{
		path: '/category/create',
		name: 'categorycreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Category/create.vue'),
	},
	{
        name: 'category_edit',
        path: '/categoryedit/:id',
		
		meta:{requiresAuth:true},

		component: () => import('../views/Category/edit.vue'),

	},

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
	if(to.meta.requiresAuth){
		const token = localStorage.getItem('token');
if(token){
	next()

}else{
	next(
		'/sign-in'
	);
}
	}else{
		next()
	}
})
export default router
